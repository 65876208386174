import img01 from "@public/images/ias-images/image_04.webp";
import img02 from "@public/images/ias-images/image_48.webp";
import img03 from "@public/images/ias-images/image_39.webp";
import img04 from "@public/images/ias-images/image_37.webp";
import img05 from "@public/images/ias-images/image_42.webp";
import img06 from "@public/images/ias-images/image_38.webp";
import img07 from "@public/images/ias-images/image_46.webp";
import img08 from "@public/images/ias-images/image_14.webp";

export const ServicesDetails01 = [
  // 01 - Industrial Automation
  {
    title: "Industrial Automation",
    image: img01,
    description:
      "Welcome to Industrial Automation and Solutions, your premier destination for cutting-edge industrial automation solutions tailored to optimize your operations and drive productivity to new heights.",
    descriptionPoint01 : "At Industrial Automation and Solutions, we specialize in harnessing the power of automation to revolutionize the way you do business. Our comprehensive Industrial Automation service encompasses the design, implementation, and optimization of automated systems that streamline processes, enhance efficiency, and unlock unparalleled potential across your operations. ",
    expect : "With Industrial Automation and Solutions, you can expect: ",
      point01: "Innovative Solutions: ",
    point02: "Seamless Integration: ",
    point03: "Enhanced Efficiency: ",
    point04: "Scalability and Flexibility: ",
    point05: "Expert Support: ",
    para01:
      "Our team of skilled engineers and technicians leverage the latest technologies and industry best practices to deliver innovative automation solutions customized to your unique needs and challenges. Whether you're looking to automate manufacturing processes, improve quality control, or enhance operational efficiency, we have the expertise and experience to bring your vision to life.",
    para02:
      "We understand the importance of seamless integration when implementing automation solutions. Our experts work closely with your team to ensure smooth integration with existing systems, minimizing disruptions and maximizing the benefits of automation from day one. ",
    para03:
      "By automating repetitive tasks and optimizing workflows, our Industrial Automation solutions help you achieve greater efficiency and productivity across your operations. From reducing cycle times to improving resource utilization, we empower you to do more with less and stay ahead of the competition. ",
    para04:
      "Our automation solutions are designed with scalability and flexibility in mind, allowing you to adapt to evolving business needs and market demands effortlessly. Whether you're expanding your operations or introducing new products, our automation solutions grow with you every step of the way. ",
    para05:
      "At Industrial Automation and Solutions, we pride ourselves on delivering exceptional customer service and support. From initial consultation to ongoing maintenance and support, our dedicated team is here to ensure your automation systems perform at their best, day in and day out. ",
    footerLine01:
      "Experience the transformative power of industrial automation with Industrial Automation and Solutions. Let us help you unlock new levels of efficiency, productivity, and profitability across your operations.",
    footerLine02:
      "Contact us today to learn more about our Industrial Automation services and how we can tailor a solution to accelerate your journey towards automation excellence. ",
  },
  // 02 - SCADA
  {
    title: "SCADA",
    image: img02,
    description:
      "Welcome to Industrial Automation and Solutions, your trusted partner for advanced SCADA solutions that empower you to monitor, control, and optimize your industrial processes with unparalleled efficiency and precision.",
    descriptionPoint01 : "At Industrial Automation and Solutions, we understand the critical role that SCADA systems play in modern industrial operations. Our comprehensive SCADA service is designed to provide you with real-time visibility into your processes, enabling informed decision-making, proactive intervention, and seamless automation. ",
      point01: "Robust Monitoring: ",
    point02: "Precise Control: ",
    point03: "Enhanced Efficiency: ",
    point04: "Scalability and Flexibility:",
    point05: "Seamless Integration:",
    para01:
      "Our SCADA solutions offer comprehensive monitoring capabilities, allowing you to visualize and analyze data from across your operations in real-time. From temperature and pressure to flow rates and production metrics, our intuitive interfaces provide you with actionable insights to drive operational excellence. ",
    para02:
      "Take control of your processes like never before with our SCADA systems. With advanced control algorithms and customizable interfaces, you can fine-tune parameters, adjust setpoints, and respond to changing conditions instantaneously, ensuring optimal performance and efficiency at all times.",
    para03:
      "By centralizing monitoring and control functions, our SCADA solutions help streamline workflows, reduce manual intervention, and minimize downtime. With automated alerts and alarms, you can address issues proactively, maximizing uptime and productivity across your operations.",
    para04:
      "Whether you're managing a small facility or a large industrial complex, our SCADA solutions are scalable to meet your evolving needs. With modular architecture and flexible configuration options, you can expand your system as your business grows, without compromising performance or reliability.",
    para05:
      "Our SCADA systems are designed for seamless integration with existing automation infrastructure, including PLCs, HMIs, sensors, and other devices. Our experienced team ensures smooth implementation and integration, minimizing downtime and disruption to your operations.",
    footerLine01:
      "Experience the power of SCADA with Industrial Automation and Solutions. Let us help you unlock new levels of efficiency, productivity, and control across your industrial processes. ",
    footerLine02:
      "Contact us today to learn more about our SCADA services and how we can tailor a solution to meet your specific requirements and objectives. ",
  },
  // 03 - IIOT
  {
    title: "IIOT",
    image: img03,
    description:
      "Welcome to Industrial Automation and Solutions, where we bring the power of the Industrial Internet of Things (IIoT) to revolutionize your industrial operations and drive unprecedented levels of efficiency, productivity, and insight.",
    descriptionPoint01: "At Industrial Automation and Solutions, we understand that the future of industrial automation lies in connected technologies that enable seamless communication, data exchange, and intelligent decision-making. Our IIoT service is designed to harness the potential of interconnected devices, sensors, and systems to transform your operations into smart, data-driven environments. ",
      point01: "Connected Devices: ",
    point02: "Real-Time Data Analytics:  ",
    point03: "Predictive Maintenance:  ",
    point04: "Remote Monitoring and Control:  ",
    point05: "Scalability and Security: ",
    para01:
      "Our IIoT solutions enable the seamless integration of sensors, actuators, machines, and other devices across your industrial ecosystem. By connecting previously isolated assets, we unlock new opportunities for data collection, analysis, and optimization. ",
    para02:
      "Gain deeper insights into your operations with real-time data analytics powered by our IIoT solutions. From monitoring key performance indicators to detecting anomalies and predicting failures, our advanced analytics tools empower you to make informed decisions and take proactive action. ",
    para03:
      "Say goodbye to costly downtime and unexpected breakdowns with our IIoT-enabled predictive maintenance solutions. By continuously monitoring equipment health and performance metrics, we help you identify potential issues before they escalate, maximizing uptime and extending asset lifespan. ",
    para04:
      "Stay connected to your operations anytime, anywhere with our IIoT-enabled remote monitoring and control capabilities. Whether you're on the factory floor or halfway around the world, our intuitive interfaces give you the visibility and control you need to keep your operations running smoothly. ",
    para05:
      "Our IIoT solutions are designed with scalability and security in mind, ensuring that your connected infrastructure can grow and evolve with your business while maintaining the highest standards of data security and privacy. ",
    footerLine01:
      "Experience the transformative potential of IIoT with Industrial Automation and Solutions. Let us be your partner in harnessing the power of connected technologies to unlock new levels of efficiency, productivity, and competitiveness in your industrial operations. ",
    footerLine02:
      "Contact us today to learn more about our IIoT services and how we can tailor a solution to meet your specific needs and objectives ",
  },
 
];
 

export const ServicesDetails02 =[
   // 04 - Project Management
   {
    title: "Project Management",
    image: img04,
    description:
      "Welcome to Industrial Automation and Solutions, where we specialize in delivering excellence through effective Project Management solutions. With our dedicated team of experienced professionals and proven methodologies, we ensure the successful execution of your projects from inception to completion. ",
    descriptionPoint01 : "At Industrial Automation and Solutions, we understand that seamless project management is essential for the timely delivery, quality assurance, and cost-effectiveness of industrial automation projects. Our Project Management service is meticulously designed to provide you with comprehensive oversight, proactive planning, and strategic execution, all tailored to meet your project requirements and objectives. ",
      point01: "Strategic Planning: ",
    point02: "Efficient Coordination: ",
    point03: "Quality Assurance: ",
    point04: "Cost Control: ",
    point05: "Timely Delivery: ",
    para01:
      "We work closely with you to develop detailed project plans, encompassing timelines, milestones, resource allocation, and risk management strategies. Our proactive approach ensures that potential challenges are identified and addressed proactively, minimizing disruptions and delays.",
    para02:
      "Our experienced project managers serve as the central point of contact, facilitating seamless communication and collaboration among stakeholders, teams, and vendors. We ensure that everyone involved is aligned with project goals and timelines, fostering a cohesive and productive working environment. ",
    para03:
      "We maintain stringent quality standards throughout the project lifecycle, conducting regular inspections, audits, and performance evaluations to uphold the integrity and reliability of deliverables. Our commitment to excellence ensures that your project meets or exceeds industry standards and expectations. ",
    para04:
      "Through careful budgeting, resource optimization, and strategic procurement, we help you manage project costs effectively without compromising on quality or performance. Our transparent cost-tracking mechanisms provide you with full visibility and control over project expenditures. ",
    para05:
      "With our proactive planning, efficient coordination, and diligent monitoring, we ensure that your project stays on schedule and is delivered within agreed-upon timelines. Our focus on adherence to deadlines enables you to realize the benefits of your investment in a timely manner. ",
    footerLine01:
      "Experience the difference that effective Project Management can make with Industrial Automation and Solutions. Let us be your trusted partner in bringing your automation projects to fruition with precision, efficiency, and excellence. ",
    footerLine02:
      "Contact us today to learn more about our Project Management services and how we can support your next automation project with tailored solutions designed to exceed your expectations. ",
  },
   // 05 - System Optimization
   {
    title: "System Optimization",
    image: img05,
    description:
      "Welcome to Industrial Automation and Solutions, where we excel in optimizing systems to enhance efficiency, reliability, and performance. Our System Optimization service is tailored to meet the dynamic needs of modern industries, ensuring your operations run seamlessly and effectively.",
    descriptionPoint01:
      "At Industrial Automation and Solutions, we understand the critical role that well-tuned systems play in driving productivity and maximizing profitability. With our expertise, we offer comprehensive solutions designed to fine-tune your systems, streamline processes, and eliminate inefficiencies. ",
    descriptionPoint02:
      "Our team of experienced professionals employs cutting-edge technologies and proven methodologies to assess, analyze, and optimize your systems. Whether it's enhancing production workflows, improving energy utilization, or minimizing downtime, we deliver customized optimization strategies that align with your unique requirements and business objectives.",
    expect: "By partnering with us for System Optimization, you can expect: ",
    point01: "Increased Efficiency: ",
    point02: "Enhanced Reliability: ",
    point03: "Improved Performance:  ",
    point04: "Cost Savings: ",
    point05: "Future-Proof Solutions:  ",
    para01:
      "We identify and address bottlenecks and inefficiencies to optimize your systems for peak performance, resulting in enhanced productivity and cost savings. ",
    para02:
      "Through meticulous analysis and optimization, we ensure that your systems operate reliably, minimizing the risk of unexpected downtime and maximizing uptime. ",
    para03:
      " Our tailored optimization solutions empower your systems to deliver superior performance, enabling you to meet and exceed operational targets consistently.",
    para04:
      "By optimizing resource utilization and enhancing efficiency, our services help reduce operating costs and improve the overall profitability of your operations. ",
    para05:
      "We design our optimization strategies with scalability and flexibility in mind, ensuring that your systems can adapt and evolve to meet the evolving demands of your industry. ",
    footerLine01:
      "Experience the transformative power of optimized systems with Industrial Automation and Solutions. Let us unlock the full potential of your operations and drive your business towards greater success. ",
    footerLine02:
      "Contact us today to learn more about our System Optimization services and how we can tailor a solution to elevate your business to new heights. ",
  },
  // 06 - Calibrations
  {
    title: "Calibrations",
    image: img06,
    description:
      "Welcome to Industrial Automation and Solutions, your trusted partner for precision and reliability in calibration services. With our unwavering commitment to accuracy and quality, we specialize in ensuring that your equipment and instruments perform at their peak efficiency, meeting the highest industry standards.",
    descriptionPoint01: "At Industrial Automation and Solutions, we understand the critical importance of precise measurements and reliable instrumentation in industrial automation. Our Calibration service is meticulously designed to verify and adjust the accuracy of your equipment, ensuring consistent and dependable performance across your operations.",
    expect: "When you choose Industrial Automation and Solutions for Calibration, you can expect: ",
    point01: "Precision Calibration: ",
    point02: "Compliance Assurance: ",
    point03: "Enhanced Reliability: ",
    point04: "Increased Efficiency: ",
    point05: "Customized Solutions: ",
    para01: "Our team of skilled technicians utilizes state-of-the-art equipment and advanced techniques to calibrate a wide range of instruments and equipment with precision and accuracy. Whether it's temperature sensors, pressure gauges, flow meters, or other critical components, we ensure that they meet or exceed industry standards. ",
    para02: "We provide comprehensive calibration services that adhere to industry regulations and standards, ensuring compliance with relevant quality and safety requirements. Our meticulous calibration procedures help you maintain regulatory compliance and mitigate the risk of costly fines or penalties. ",
    para03: "By calibrating your equipment regularly, we help minimize the risk of inaccuracies and inconsistencies, enhancing the reliability and repeatability of your measurements. Our calibration services enable you to make informed decisions confidently, knowing that your instruments are delivering accurate results. ",
    para04: "Properly calibrated equipment operates more efficiently, leading to improved productivity and cost savings. Our Calibration service helps optimize the performance of your instrumentation, reducing downtime and maximizing operational efficiency. ",
    para05: "We understand that every industry and application has unique calibration requirements. That's why we offer tailored calibration solutions designed to meet your specific needs and challenges, ensuring optimal performance and reliability for your equipment. ",
   footerLine01: "Experience the peace of mind that comes with precise and reliable calibration services from Industrial Automation and Solutions. Let us be your trusted partner in ensuring the accuracy and performance of your instrumentation, empowering you to achieve your operational goals with confidence. ",
   footerLine02: "Contact us today to learn more about our Calibration services and how we can support your business with precision solutions tailored to your requirements."
  },

 

];

export const TwoServices = [
    // 07 - Earthings/Grounding
    {
      title: "Earthings/Grounding",
      image: img07,
      description:
        "Welcome to Industrial Automation and Solutions, where we empower your industrial operations with cutting-edge technologies and innovative solutions. Introducing our essential service: Earthing & Grounding.",
      descriptionPoint01: "At Industrial Automation and Solutions, we recognize that a strong foundation is paramount for the safety and efficiency of your electrical systems. Our Earthing & Grounding service is meticulously designed to ensure a secure connection between your equipment and the earth, mitigating the risk of electrical hazards and enhancing overall reliability. ",
      expect: "Here's what you can expect from our Earthing & Grounding solutions:  ",
      point01: "Safety and Reliability: ",
      point02: "Comprehensive Solutions: ",
      point03: "Compliance and Standards: ",
      point04: "Risk Mitigation: ",
      para01: "We prioritize the safety of your personnel and the longevity of your equipment by establishing robust connections to the earth, safeguarding against electrical hazards and minimizing disruptions to your operations. ",
      para02: "Our expert team offers comprehensive solutions tailored to your specific industrial needs, whether you require grounding for a new facility or upgrades to existing systems. ",
      para03: "Rest assured that our Earthing & Grounding solutions adhere to industry standards and regulations, ensuring compliance and peace of mind for your organization. ",
      para04: "By identifying and addressing potential risks associated with improper grounding, we help you mitigate the threat of equipment damage, downtime, and costly repairs. ",
      footerLine01: "Experience the confidence and peace of mind that come with our Earthing & Grounding services. Trust Industrial Automation and Solutions to fortify your industrial infrastructure and elevate your operations to new heights of safety and efficiency. ",
     footerLine02: "Contact us today to learn more about how our Earthing & Grounding solutions can benefit your organization." 
    },
 // 08 - Equipment Repairing
 {
  title: "Equipment Repairing",
  image: img08,
  description:
    "Welcome to Industrial Automation and Solutions, where we go beyond just providing equipment—we ensure it stays in optimal condition with our top-tier Equipment Repairing service.",
  descriptionPoint01: "At Industrial Automation and Solutions, we recognize the critical importance of maintaining your industrial equipment for seamless operations. Our Equipment Repairing service is crafted to address all your maintenance and repair needs promptly and effectively. ",
  expect: "Here's what distinguishes our Equipment Repairing service: ",
  point01: "Expertise and Precision: ",
  point02: "Efficiency and Reliability: ",
  point03: "Comprehensive Solutions: ",
  point04: "Quality Assurance: ",
  point05: "Cost-Effective Solutions: ",
  para01: "Our team of highly skilled technicians possesses extensive experience and technical knowledge across a wide range of industrial equipment. From mechanical components to sophisticated electronic systems, we have the expertise to diagnose and repair issues with precision.",
  para02: "We understand that downtime is costly for your operations. That's why we prioritize efficiency in our repair processes, aiming for swift turnaround times without compromising on quality. You can rely on us to restore your equipment to optimal working condition quickly and effectively. ",
  para03: "Whether it's routine maintenance, troubleshooting, or major repairs, our Equipment Repairing service offers comprehensive solutions tailored to your specific needs. We address not only the symptoms but also the root causes of equipment issues, ensuring long-term reliability and performance. ",
  para04: "We stand behind the quality of our workmanship and the parts we use in our repairs. With stringent quality control measures in place, you can trust that your equipment will be repaired to the highest standards, meeting or exceeding manufacturer specifications. ",
  para05 : "We understand the importance of cost-effectiveness in equipment maintenance. Our transparent pricing and competitive rates ensure that you receive value for your investment, helping you minimize expenses while maximizing the lifespan of your equipment.",
  footerLine01: "Experience the peace of mind that comes with entrusting your equipment repairs to Industrial Automation and Solutions. Let us be your trusted partner in maintaining the reliability and efficiency of your industrial operations.",
 footerLine02: "Contact us today to learn more about our Equipment Repairing service and how we can support your maintenance needs."

},
];
