import PopUpIcon from "@public/images/ias-images/message.gif";
import Image from 'next/image';
import Link from 'next/link';
import { useState } from "react";
import { Col, Row } from 'react-bootstrap';


const PopUp = () => {
  return (
    <>
   {/* <BreadcrumbMain page="Contact Us" imgBackground={imgBackground} /> */}
      <Row className="popup-main">
        <Col className="popup-col">
          <div className="icon">
            <Image src={PopUpIcon} alt="icon" periority="true" className="icon-gif" />
          </div>
          <div className="contentpopUp">
            <h2 className="heading">Thank You </h2>
            <p className="para">Your message is submitted we will contact you soon</p>
            <p className="para">
            Click here to go back
            <Link href="/">Here</Link>
            </p>
            </div>        
        </Col>
      </Row>
    </>
  )
}

export default PopUp;