import { Dropdown } from "react-bootstrap";

const DropDown = (props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {props.dropdownName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="/our-services">Electronic Card </Dropdown.Item>
        <Dropdown.Item href="/our-services">Industrial UPS / Threephase UPS</Dropdown.Item>
        <Dropdown.Item href="/our-services">VFDs</Dropdown.Item>
        <Dropdown.Item href="/our-services">HMIs</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4">Any Industrial appliances</Dropdown.Item>    
      </Dropdown.Menu>

    </Dropdown>
  );
};

export default DropDown;
