import { Navigation } from "@app/config/navigation";
import useMediaScreens from "@app/hooks/useMediaquery";
import logo from "@public/images/IAS.webp";
import closeIcon from "@public/images/icons/close-icon.png";
import hamburgerIcon from "@public/images/icons/hamburger-icon.png";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactModal from "../modal/contactmodal";

const Header = () => {
  const { isMobile } = useMediaScreens();
  const router = useRouter();
  const [scrolled, setScrolled] = useState(false);
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    if (isMobile.landscape || isMobile.portrait) {
      setShowNav(false);
    }
  }, [router]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrolled(position > 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggleNavMenu = () => {
    setShowNav(!showNav);
  };

  return (
    <header
      className={`${scrolled && "scrolled header-top-line"} ${
        showNav && "mobile-navigation-visible"
      }`}
    >
      <Container>
        <Row className={`align-items-center align-items-xl-center`}>
          <Col xs={4} md={2}>
            <Link href="/">
              <Image
                src={logo}
                alt="logo"
                priority={true}
                className="brand-logo"
              />
            </Link>
          </Col>

          <Col xs={8} md={10}>
            <div className={"navigation-container"}>
              <ul className={`${!showNav && "hide-nav"}`}>
                {Navigation.length > 0 &&
                  Navigation.map((navItem, index) => (
                    <li key={index}>
                      <Link
                        href={navItem?.to}
                        className={`${
                          router.pathname === navItem.to ? "active" : ""
                        }`}
                      >
                        <label>{navItem?.label}</label>
                      </Link>
                    </li>
                  ))}
                  <ContactModal />
              </ul>
              <div className="nav-open-close-btn-container">
                {showNav ? (
                  <Image
                    src={closeIcon}
                    alt="closeIcon"
                    onClick={handleToggleNavMenu}
                    priority
                  />
                ) : (
                  <Image
                    src={hamburgerIcon}
                    alt="hamburgerIcon"
                    onClick={handleToggleNavMenu}
                    priority
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
