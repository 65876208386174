import PopUp from "@app/components/popup";
import { ValidationError, useForm } from "@formspree/react";
import { Form } from 'react-bootstrap';

const ContactForm = () => {
    const [state, handleSubmit] = useForm("mleqalpk");
    if (state.succeeded) {
      return <PopUp />;
    }
  return (
    <>
      <Form onSubmit={handleSubmit} style={{width:"100%"}}>
              <Form.Group className="mb-3" >
              <Form.Label htmlFor="name">Name (Required)*</Form.Label>
                <Form.Control type="text" placeholder="Name" required id="name" name="name"/>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label htmlFor="email">
                  Email address (Required)*
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  required
                  id="email"
                  name="email"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label htmlFor="phone">
                  Phone (Required)*
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Phone"
                  required
                  id="phone"
                  name="phone"
                />
                <ValidationError
                  prefix="phone"
                  field="phone"
                  errors={state.errors}
                />
              </Form.Group>
              <Form.Label>Message (Required)*</Form.Label>
              <Form.Control
                as="textarea"
                className="textarea"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                id="message"
                name="message"
              />
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
              <button variant="contained"
                className="form-btn"
                type="submit"
                disabled={state.submitting}
              >
                Submit Message
              </button>
            </Form>
    </>
  )
}

export default ContactForm;