import fb from "@public/images/icons/facebook.png";
import insta from "@public/images/icons/instagram.png";
import linkdin from "@public/images/icons/linkedin.png";
import mark from "@public/images/icons/location.png";
import mail from "@public/images/icons/mail.png";
import plane from "@public/images/icons/paper-plane.png";
import call from "@public/images/icons/phone-call.png";
import twitter from "@public/images/icons/twitter.png";
import youtube from "@public/images/icons/youtube.png";
import Image from "next/image";
import DropDown from "../buttons/dropdown/dropdown";
import Link from "next/link";
import { ValidationError, useForm } from "@formspree/react";
import { ServicesDetails01 } from "@app/config/services";
import { useEffect, useState } from "react";

const Footer = () => {
  const [state, handleSubmit] = useForm("mleqalpk");
  const [subscribe , setSubscribe] = useState("")
  console.log(state)
  useEffect(()=>{
    if (state.succeeded) {
     alert("Email Sent Successfully"); 
      setSubscribe("");
    }
  },[state.succeeded])
  const handleChange = (e) => {
    setSubscribe(e.target.value)
  }

  return (
    <>
      <section className="w3l-footer-29-main">
        <div className="footer-29 py-5">
          <div className="container py-lg-4">
            <div className="row footer-top-29">
              <div className="footer-list-29 col-lg-4">
                <h6 className="footer-title-29">About IAS</h6>
                <p>
                  At Industrial Automation and Solutions (IAS), we are dedicated
                  to revolutionizing the industrial landscape by providing
                  cutting-edge automation services and tailored solutions. With
                  a commitment to efficiency, reliability, and technological
                  excellence, we bring a new era of productivity to our clients
                  in various industries.
                </p>
                <div className="main-social-footer-29 mt-4">
                  <a
                    href="https://www.facebook.com/profile.php?id=61555019721008"
                    className="ancor facebook"
                  >
                    <Image src={fb} alt="facebook" className="icon" priority />
                  </a>
                  <a href="#twitter" className="ancor twitter left">
                    <Image src={twitter} alt="twitter" className="icon" priority />
                  </a>
                  <a href="#instagram" className="ancor instagram left">
                    <Image src={insta} alt="insta" className="icon" priority />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/industrial-automation-and-solutions/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BEwJvPlo2Rd%2BFDkJL1QjGyw%3D%3D"
                    className="ancor linkedin left"
                  >
                    <Image src={linkdin} alt="linkedin" className="icon" priority />
                  </a>
                  <a
                    href="https://www.youtube.com/@IAS-pc3lv"
                    className="ancor youtube left"
                  >
                    <Image src={youtube} alt="youtube" className="icon" priority />
                  </a>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-4 footer-list-29 footer-2 mt-lg-0 mt-5">
                <ul className="margin-div">
                  <h6 className="footer-title-29">Useful Links</h6>
                 <li>
                  <Link href="/">Home</Link>
                  </li>
                  <li>
                    <Link href="/about-us">About</Link>
                    </li>
                    <li>
                      <Link href="/our-services">Services</Link>
                    </li>
                  <li>
                    <Link href="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link href="/contact-us" >Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                <ul className="margin-div">
                  <h6 className="footer-title-29">Services</h6>
                  <li>
                    <Link href={ServicesDetails01[0]}>Industrial Automation</Link>
                  </li>
                  <li>
                    <Link href="/our-services">SCADA</Link>
                  </li>
                  <li>
                    <Link href="/our-services">IIOT</Link>
                  </li>
                  <li>
                    <Link href="/our-services">Project Management</Link>
                  </li>
                  <li>
                    <Link href="/our-services">Calibrations</Link>
                  </li>
                  <li>
                    <Link href="/our-services">Earthings/Grounding</Link>
                  </li>
                  <li>
                    <DropDown dropdownName="Repairing" />
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-8 footer-list-29 footer-1 mt-lg-0 mt-5">
                <h6 className="footer-title-29">Contact Us</h6>
                <ul className="contact-ul">
                  <li>
                    <p className="contact-p">
                      <Image
                        src={mark}
                        alt="location"
                        className="contact-icons"
                        priority
                      />
                      Karachi, Pakistan
                    </p>
                  </li>
                  <li>
                    <a href="tel: +92 331 2284121" className="contact-a">
                      <Image
                        src={call}
                        alt="contact"
                        className="contact-icons"
                        priority
                      />
                      +92 331 2284121
                    </a>
                  </li>
                  <li className="email">
                    <a
                      href="mailto:info@industrialautomationandsolutions.com"
                      className="mail contact-a"
                    >
                      <Image
                        src={mail}
                        alt="location"
                        className="contact-icons small"
                        priority
                      />
                      info@industrialautomationandsolutions.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12 footer-list-29 footer-4 mt-5">
                <div className="column1 align-self">
                  <h6 className="footer-title-29 mb-1">
                    Subscribe to our Newsletter{" "}
                  </h6>
                  <p>Enter your email and receive the latest news from us.</p>
                </div>
                <div className="column1">
                  <form  className="subscribe" method="post" onSubmit={handleSubmit}>
                    <input
                    type="email"
                  placeholder="Your Email Address"
                    required
                    id="email"
                    name="email"
                    value={subscribe}
                    className="input"
                    onChange={handleChange}
                      />
                      <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <input type="hidden" id="name" name="name"value="Subscribed As Our Newsletter" />
                    <button className="btn-footer" type="submit"
                disabled={state.submitting}>
                      <Image src={plane} alt="paper-plane" className="plane" priority />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="tag">
        <p>
          © 2024{" "}
          <a href="https://industrialautomationandsolutions.com/">
            Industrial Automation & Solutions
          </a>
          . All rights reserved. Design & developed by{" "}
          <strong>
            <a href="https://www.wattonetech.in/" style={{ color: "#f3c512" }}>
              WattoneTechnologies
            </a>
          </strong>
        </p>
      </div>
    </>
  );
};

export default Footer;
