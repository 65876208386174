import ContactForm from "@app/components/contactform";
import { useState } from "react";
// import { ValidationError, useForm } from "@formspree/react";
// import PopUp from "@app/components/popup";


const { Modal, Button, Form } = require("react-bootstrap");

const ContactModal = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Button variant="primary" className="nav-btn" onClick={handleShow}>
        Get a Quote
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Your Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ContactForm />
            {/* <Button
            variant="outline-primary"
            onClick={handleClose}
            className="btn-outlined"
          >
            Close
          </Button> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContactModal;
