import MainLayout from "@app/layouts/main";
import "@app/styles/style.scss";
import "bootstrap/scss/bootstrap.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function App({ Component, pageProps }) {
  return (
    <div>
      <MainLayout>
        <Component {...pageProps} />
      </MainLayout>
    </div>
  );
}
